import gsap from 'gsap'

window.scrollTo({ top: 0 })
let autoScroll = true

function startScrolling () {
  gsap.set('body', { overflow: 'auto' })

  window.addEventListener('mousemove', showUi)
  window.addEventListener('mousedown', showUi)
  window.addEventListener('touchstart', showUi)
  window.addEventListener('wheel', showUi)

  const start = Date.now()
  let time = 0
  let timeDiff = 0

  function animate () {
    const nextTime = Date.now() - start
    timeDiff += nextTime - time
    time = nextTime

    if (timeDiff / 20 > 1) {
      if (autoScroll) {
        const old = window.scrollY
        window.scrollBy({ top: 1 })
        if (old === window.scrollY) {
          autoScroll = false
          toggleAutoScrollBtn.textContent = '(turn autoscroll on)'
        }
      }
      timeDiff = 0
    }

    requestAnimationFrame(animate)
  }

  animate()
}

const toggleAutoScrollBtn = document.querySelector('.toggle-autoscroll')
toggleAutoScrollBtn.addEventListener('click', () => {
  autoScroll = !autoScroll
  toggleAutoScrollBtn.textContent = autoScroll ? '(stop autoscroll)' : '(resume autoscroll)'
})

const tl = gsap.timeline()

gsap.set('body', { overflow: 'hidden' })

const title = document.querySelector('.main-title')
title.textContent = 'Hands off!'

tl.set(title, { opacity: 0 }, 0)
  .set(title, { opacity: 1 }, '+=1')
  // .set(title, { opacity: 0 }, '+=0.09')
  // .set(title, { opacity: 1 }, '+=0.62')
  // .set(title, { opacity: 0 }, '+=0.12')
  // .set(title, { opacity: 1 }, '+=0.25')
  // .set(title, { opacity: 0 }, '+=0.06')
  // .set(title, { opacity: 1 }, '+=0.08')
  .set(title, { opacity: 0 }, '+=2')
  .add(() => { title.textContent = 'Malo de Widerspach' })
  .set(title, { opacity: 1 }, '+=1.5')
  // .set(title, { opacity: 0 }, '+=0.1')
  // .set(title, { opacity: 1 }, '+=0.12')
  // .set(title, { opacity: 0 }, '+=0.17')
  // .set(title, { opacity: 1 }, '+=0.31')
  // .set(title, { opacity: 0 }, '+=0.11')
  // .set(title, { opacity: 1 }, '+=0.15')
  .add(startScrolling, '+=2')

let h = setTimeout(() => {}, 0)
function showUi () {
  document.body.classList.toggle('ui-hidden', false)
  clearTimeout(h)
  h = setTimeout(() => document.body.classList.toggle('ui-hidden', true), 2000)
}
